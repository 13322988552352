import React from "react";
import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./app/App";
import StateProvider from "./app/components/StateProvider";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import {Provider} from "react-redux";
import {applyMiddleware, compose, createStore} from "redux";
import rootReducer from "./app/redux";
import thunk from "redux-thunk";
import ErrorBoundary from "./app/components/errorBoundary/errorBoundary";
import {createTheme, ThemeProvider} from "@material-ui/core";
import {storeConfig} from "./app/redux/index";
import AuthContext from "./app/contexts/auth.context";

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration(),
        Sentry.replayIntegration(),
    ],
    release: process.env.VERCEL_GIT_COMMIT_SHA,
    environment: process.env.NODE_ENV,
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate -
    // For example; a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // result in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export const store = createStore(rootReducer, applyMiddleware(thunk));
const theme = createTheme();

ReactDOM.render(
    <React.StrictMode>
        <Provider store={storeConfig}>
            <Provider store={store}>
                <AuthContext.Provider value={storeConfig.getState().auth}>
                    <StateProvider>
                        <ErrorBoundary>
                            <ThemeProvider theme={theme}>
                                <App/>
                            </ThemeProvider>
                        </ErrorBoundary>
                    </StateProvider>
                </AuthContext.Provider>
            </Provider>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);

// EMBED TAWK SCRIPT
// var Tawk_API = Tawk_API || {},
//     Tawk_LoadStart = new Date();
(function () {
    var s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = "https://embed.tawk.to/60ee7056649e0a0a5ccc1971/1fahmdkhe";
    s1.setAttribute("crossorigin", "*");
    s0.parentNode.insertBefore(s1, s0);
})();

// END EMBED TAWK SCRIPT

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example, reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
